import React, { useState } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { ReactComponent as DateIconGray } from 'svg/node/calendar-gray.svg';
import { ReactComponent as DateIconRed } from 'svg/node/calendar-red.svg';
import { ReactComponent as DateIconGreen } from 'svg/node/calendar-green.svg';

import styles from './Nodes.module.css';

const { RangePicker } = DatePicker;

const PromoCalendar = ({ selectIndex, data, handleDateChange }) => {
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);

  const getDateIcon = (selectedId) => {
    const promoTemplate = data.promoTemplates[selectedId];
    if (!promoTemplate?.start || !promoTemplate?.end) {
      return <DateIconGray style={{ cursor: 'pointer' }} />;
    }
    return dayjs(promoTemplate.end).isBefore(dayjs()) ? (
      <DateIconRed style={{ cursor: 'pointer' }} />
    ) : (
      <DateIconGreen style={{ cursor: 'pointer' }} />
    );
  };

  return (
    <div className={styles.datePickerContainer}>
      <RangePicker
        value={[
          data.promoTemplates[data.selected[selectIndex]]?.start
            ? dayjs(data.promoTemplates[data.selected[selectIndex]].start)
            : null,
          data.promoTemplates[data.selected[selectIndex]]?.end
            ? dayjs(data.promoTemplates[data.selected[selectIndex]].end)
            : null,
        ]}
        onChange={(dates) => handleDateChange(selectIndex, dates)}
        open={isDatePickerVisible}
        style={{ opacity: 0, pointerEvents: 'none', position: 'absolute' }}
        onOpenChange={setIsDatePickerVisible}
      />
      <div onClick={() => setIsDatePickerVisible(!isDatePickerVisible)}>
        {getDateIcon(data.selected[selectIndex])}
      </div>
    </div>
  );
};

export default PromoCalendar;
